import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";

import WeekSelectorComponent from "../components/WeekSelectorComponent/WeekSelectorComponent";
import WeeklyCommonComponent from "../components/WeeklyCommonComponent/WeeklyCommonComponent";
import NewComponent from "../components/NewComponent/NewComponent";

import AccountingService from "../services/AccountingService";
import NewService from "../services/NewService";
import { REPORTING_DAYS_TYPES, WEEKS_START } from "../helpers/constants";
import WeeksList from "../helpers/WeekList";
import { useMyContext } from "../context";

function MainPage() {
  const accountingService = new AccountingService();
  const newSerivce = new NewService();
  const weeksList = new WeeksList(WEEKS_START);

  const { selectedWeekIndex } = useMyContext();

  const [selectedWeek, setSelectedWeek] = useState(null);

  const [newWeekData, setWeekData] = useState([]);
  const [newWeekTotals, setWeekTotals] = useState([]);
  const [newWeekEndData, setWeekEndData] = useState([]);
  const [newWeekEndTotals, setWeekEndTotals] = useState([]);

  const [currentReportingDaysTypes, setCurrentReportingDaysTypes] = useState(
    REPORTING_DAYS_TYPES?.weekdays
  );

  useEffect(() => {
    onWeekChange(selectedWeek);
  }, [selectedWeek]);

  useEffect(() => {
    const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekdays) {
      const newDate = new Date(newWeekPeriod?.dateFrom);
      newDate.setDate(newDate.getDate() + 6);
      newWeekPeriod.dateTo = moment(newDate).format("YYYY-MM-DD");
    }
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekends) {
      const newDate = new Date(newWeekPeriod?.dateTo);
      newDate.setDate(newDate.getDate() - 1);
      newWeekPeriod.dateFrom = moment(newDate).format("YYYY-MM-DD");
    }
    setSelectedWeek(newWeekPeriod);
  }, [currentReportingDaysTypes, selectedWeekIndex]);

  async function onWeekChange(selectedWeek) {
    if (selectedWeek) {
      const newTo = new Date(selectedWeek.dateFrom);
      newTo.setDate(newTo.getDate() + 4);

      getDataW(selectedWeek.dateFrom, moment(newTo).format("YYYY-MM-DD"));

      const newFrom = new Date(selectedWeek.dateFrom);
      newFrom.setDate(newFrom.getDate() + 5);

      getDataWe(moment(newFrom).format("YYYY-MM-DD"), selectedWeek.dateTo);
    }
  }

  async function getDataW(dateFrom, dateTo) {
    if(selectedWeek){
      const weekData = await newSerivce.getOfficesList(
        dateFrom,
        dateTo
      );
      setWeekData(weekData.data.data);
      setWeekTotals(weekData.data.total_statistics);
    }
  }

  async function getDataWe(dateFrom, dateTo) {
    if(selectedWeek){
      const weekEndData = await newSerivce.getOfficesListWe(
        dateFrom,
        dateTo
      );
      setWeekEndData(weekEndData.data.data);
      setWeekEndTotals(weekEndData.data.total_statistics);
    }
  }

  function handleTabChange(key){
    //console.log(key);
  }

  return (
    <div className="card">
      
      <div className="card-header p-3">
        <div className="home-link">
          <Link to="/" className="pb-1">
            Список офисов
          </Link>
        </div>
        <div className="name-div bold-row">&nbsp;</div>
        <div className="selector-div">
          <WeekSelectorComponent onWeekChange={onWeekChange} />
        </div>
      </div>
      <Tabs
        defaultActiveKey="new"
        onSelect={handleTabChange}
      >
        <Tab
          title="Недельный отчет"
          eventKey={"new"}
        >
          <NewComponent
              key="table_with_data"
              tableData={newWeekData}
              tableTotals={newWeekTotals}
          />
        </Tab>
        <Tab
          eventKey={"weekend"}
          title="Субботний отчет"
        >
          <NewComponent
            key="table_with_data"
            tableData={newWeekEndData}
            tableTotals={newWeekEndTotals}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default MainPage;
