import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function NewComponent(props) {
  const [weekdaysTableData, setWeekdaysTableData] = useState([]);
  const [newWeekData, setWeekData] = useState([]);
  let counter = 0;
  let reported = 0;

  function to_local(value) {
    var output = " "
    if (typeof value != 'undefined') {
      output = value?.toLocaleString("ru-RU", { maximumFractionDigits: 0 })
    }
    return output;
  }

  function totalDataItems(){
    if (typeof props.tableTotals !== undefined) {
      if (props.tableTotals[0]) {
        return (
        <tr className="bold-row">
          <td>&nbsp;</td>
          <td>Всего:</td>
          <td className="align-right">{to_local(props.tableTotals[0].total_incomes)}</td>
          <td className="align-right">{to_local(props.tableTotals[0].total_withdrawals)}</td>
          <td className="align-right">{to_local(props.tableTotals[0].total_salaries)}</td>
          <td className="align-right">{to_local(props.tableTotals[0].total_expenses)}</td>
          <td className="align-right">{to_local(props.tableTotals[0].total_expenses_plus_salaries)}</td>
          <td className={ props.tableTotals[0].accounting_income > 0 ? "align-right" : "align-right-red" }>{to_local(props.tableTotals[0].accounting_income)}</td>
          <td className="align-right">{to_local(props.tableTotals[0].income_prc)}</td>
          <td className="align-right">{to_local(props.tableTotals[0].balance_history/1)}</td>
        </tr>
        )
      }
    }
  }

  function tableDataItems() {
    counter = 0;
    reported = 0;
    var classname = "";

    return props.tableData.map((row,index) => {
     counter = counter + 1;
    //  console.log(index+1, row.name, row.accounting_income);
      if (row.accounting_income == null) {
         classname = "table-danger";
         reported = reported + 1;
      } else {
        if (row.accounting_income > 0) {
          classname = "table-success";
        }
        else {
          classname ="";
        }
      }
      return (
        <tr className={classname}>
          <td>{index + 1}</td>
          <td>
            <Link 
              to={`/office/${row.id}`}
              // target="_blank"
            >
              {row.name}
            </Link>
          </td>
          <td className="align-right">{to_local(row.total_incomes)}</td>
          <td className="align-right">{to_local(row.total_withdrawals)}</td>
          <td className="align-right">{to_local(row.total_salaries)}</td>
          <td className="align-right">{to_local(row.total_expenses)}</td>
          <td className="align-right">{to_local(row.total_expenses_plus_salaries)}</td>
          <td className={ row.accounting_income > 0 ? "align-right" : "align-right-red" }>{to_local(row.accounting_income)}</td>
          <td className="align-right">{to_local(row.income_prc)}</td>
          <td className="align-right">{to_local(row.balance_history/1)}</td>
        </tr>
      )
    })
  }

  return (
    <div className="card-body">
      <table className="table table-hover">
        <thead id="tableHeader">
          <tr>
            <th>#</th>
            <th>Офис</th>
            <th>Приход</th>
            <th>Вывод</th>
            <th>Зарплата</th>
            <th>Расход нал</th>
            <th>Расход + ЗП</th>
            <th>Прибыль</th>
            <th>%%</th>
            <th>Остаток</th>
          </tr>
        </thead>
        <tbody id="content">
          {totalDataItems()}
          {tableDataItems()}
        </tbody>
        <tfoot>
          {totalDataItems()}
        </tfoot>
      </table>
    </div>

  );
}
