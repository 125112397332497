import { useEffect, useState } from "react";
import { toLocal } from "../../helpers/methods";

export default function ExpenseComponent(props) {

  function totalDataItems(){
    if (typeof props.statData !== undefined) {
      if (props.statData[0]) {
        return (
        <tr className="bold-row">
          <td>&nbsp;</td>
          <td>Всего:</td>
          <td>&nbsp;</td>
          {/* <td>&nbsp;</td> */}
          <td className="align-right">{toLocal(props.statData[0].cash)}</td>
          <td className="align-right">{toLocal(props.statData[0].crypto)}</td>
        </tr>
        )
      }
    }
  }

  function tableDataItems() {
    if (typeof props.expenseData !== 'undefined') {
      return props.expenseData.map((row,index) => {
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{row.date}</td>
            <td>{row.name}</td>
            {/* <td>{row.details}</td> */}
            <td className="align-right">{toLocal(row.cash/1)}</td>
            <td className="align-right">{toLocal(row.crypto/1)}</td>
          </tr>
        )
        }
      )
    }
  }

  return (
    <div className="card-body">
      <table className="table table-hover">
        <thead id="tableHeader">
          <tr>
            <th className="id-cell">#</th>
            <th className="column-min-100">Дата</th>
            <th>Комментарий</th>
            {/* <th>Детали</th> */}
            <th>Сумма,<br/>Нал</th>
            <th>Сумма,<br/>Crypto</th>
          </tr>
        </thead>
        <tbody id="content">
          {totalDataItems()}
          {tableDataItems()}
        </tbody>
        <tfoot>
          {totalDataItems()}
        </tfoot>
      </table>
    </div>

  );
}
