import moment from "moment";

export function getDayOfWeek(date) {
  const day = moment(date).format("dddd");
  switch (day) {
    case "Monday":
      return "ПН";
    case "Tuesday":
      return "ВТ";
    case "Wednesday":
      return "СР";
    case "Thursday":
      return "ЧТ";
    case "Friday":
      return "ПТ";
    case "Saturday":
      return "СБ";
    case "Sunday":
      return "ВС";
    default:
      console.log("Sorry, we are out of " + day + ".");
  }
}


export function toLocal(value) {
  var output = " ";
  if (typeof value != 'undefined') {
    var to_convert = Number(value);
    output = to_convert.toLocaleString("ru-RU", { maximumFractionDigits: 0 });
  }
  return output;
}


export function to_local(value) {
  var output = " "
  if (typeof value != 'undefined') {
    output = value?.toLocaleString("ru-RU", { maximumFractionDigits: 0 })
  }
  return output;
}