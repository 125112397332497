import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";

import IncomeComponent from "../components/IncomeComponent/IncomeComponent";
import ExpenseComponent from "../components/ExpenseComponent/ExpenseComponent";
import ReportComponent from "../components/ReportComponent/ReportComponent";
import WeekSelectorComponent from "../components/WeekSelectorComponent/WeekSelectorComponent";

import WeeksList from "../helpers/WeekList";
import { WEEKS_START, REPORTING_DAYS_TYPES } from "../helpers/constants";
import AccountingService from "../services/AccountingService";
import NewService from "../services/NewService";
import { useMyContext } from "../context";

function OfficePage() {
  const weeksList = new WeeksList(WEEKS_START);
  const newService = new NewService();

  const { selectedWeekIndex } = useMyContext();
  const { officeId } = useParams();
  const accountingService = new AccountingService();

  const [selectedWeek, setSelectedWeek] = useState(null);
  const [officeName, setOfficeState] = useState("");
  const [reportData, setReportData] = useState([]);
  const [statData, setStatData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [reportDataWe, setReportDataWe] = useState([]);
  const [statDataWe, setStatDataWe] = useState([]);
  const [incomeDataWe, setIncomeDataWe] = useState([]);
  const [expenseDataWe, setExpenseDataWe] = useState([]);
  const [currentReportingDaysTypes, setCurrentReportingDaysTypes] = useState(
    REPORTING_DAYS_TYPES?.weekdays
  );

  useEffect(() => {
    (async function () {
      const res = await accountingService.getOfficeData(officeId);
      if (res.data.status) {
        setOfficeState(res.data.data.name);
      }
    })();
  }, []);

  useEffect(() => {
    const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
    setSelectedWeek(newWeekPeriod);
  }, [selectedWeekIndex]);

  useEffect(() => {
    if(selectedWeek){

      const newTo = new Date(selectedWeek.dateFrom);
      newTo.setDate(newTo.getDate() + 4);

      getDataR(selectedWeek.dateFrom, moment(newTo).format("YYYY-MM-DD"));

      const newFrom = new Date(selectedWeek.dateFrom);
      newFrom.setDate(newFrom.getDate() + 5);

      getDataWe(moment(newFrom).format("YYYY-MM-DD"), selectedWeek.dateTo);
    }
    }, [selectedWeek]
  );

  async function getDataR(dateFrom, dateTo) {
    setCurrentReportingDaysTypes(REPORTING_DAYS_TYPES?.weekdays);
    if(selectedWeek){
      const weData = await newService.getReport(
        officeId,
        dateFrom,
        dateTo
      );
      setReportData(weData.data.report);
      setStatData(weData.data.statistics);
      setIncomeData(weData.data.income);
      setExpenseData(weData.data.expense);
    }
  }

  async function getDataWe(dateFrom, dateTo) {
    setCurrentReportingDaysTypes(REPORTING_DAYS_TYPES?.weekends);
    if(selectedWeek){
      const weekData = await newService.getReportWe(
        officeId,
        dateFrom,
        dateTo
      );
      setReportDataWe(weekData.data.report);
      setStatDataWe(weekData.data.statistics);
      setIncomeDataWe(weekData.data.income);
      setExpenseDataWe(weekData.data.expense);
    }
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-header p-3">
          <div className="home-link">
            <Link to="/" className="pb-1">
              Список офисов
            </Link>
          </div>
          <div className="name-div bold-row"><p className="centered">{officeName}</p></div>
          <div className="selector-div">
            <WeekSelectorComponent />
          </div>
        </div>
        <div className="card-body">
          <Tabs
            defaultActiveKey="weekdays"
            onSelect={setCurrentReportingDaysTypes}
          >
            <Tab
              eventKey="weekdays"
              title="Недельный отчет"
            >
              <ReportComponent
                key="report_header"
                reportData={reportData}
                statData={statData}
              />
              <Tabs>
                <Tab eventKey="income" title="Приход">
                  <IncomeComponent
                    key="report_header"
                    incomeData={incomeData}
                    statData={statData}
                  />
                </Tab>
                <Tab eventKey="expense" title="Расход">
                  <ExpenseComponent
                    key="report_header"
                    expenseData={expenseData}
                    statData={statData}
                  />
                </Tab>
              </Tabs>
            </Tab>
            <Tab
              eventKey="weekend"
              title="Субботний отчет"
            >
              <ReportComponent
                key="report_header"
                reportData={reportDataWe}
                statData={statDataWe}
              />
              <Tabs>
                <Tab eventKey="income" title="Приход">
                  <IncomeComponent
                    key="report_header"
                    incomeData={incomeDataWe}
                    statData={statDataWe}
                  />
                </Tab>
                <Tab eventKey="expense" title="Расход">
                  <ExpenseComponent
                    key="report_header"
                    expenseData={expenseDataWe}
                    statData={statDataWe}
                  />
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OfficePage;
