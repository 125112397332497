import axiosInstance from "../axios";

class NewService {

  getReport = async (officeId, dateFrom, dateTo) => {
    const data = [];
    const report = await axiosInstance(
      `/accounting/${officeId}/report?start_date=${dateFrom}&end_date=${dateTo}&page=1`
    );

    return { status: true, data: report.data };
  };

  getReportWe = async (officeId, dateFrom, dateTo) => {
    const data = [];
    const report = await axiosInstance(
      `/accounting/${officeId}/reportwe?start_date=${dateFrom}&end_date=${dateTo}&page=1`
    );

    return { status: true, data: report.data };
  };

  getOfficesList = async (dateFrom, dateTo, isWeekends = true) => {

    const weekdaysData = await axiosInstance(
      `/accounting/offices_new`,
      {
        params: {
          start_date: dateFrom,
          end_date: dateTo,
          page: 1,
        },
      }
    );

    return { status: true, data: weekdaysData.data };
  };

  getOfficesListWe = async (dateFrom, dateTo, isWeekends = true) => {

    const weekdaysData = await axiosInstance(
      `/accounting/offices_we`,
      {
        params: {
          start_date: dateFrom,
          end_date: dateTo,
          page: 1,
        },
      }
    );

    return { status: true, data: weekdaysData.data };
  };
}

export default NewService;
